import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { SessionService } from '../shared/services/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../shared/models/user';
import { UserService } from '../shared/services/user.service';
import { NotificationService } from '../shared/services/notification.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserDialogConfigs } from './dialog-configs';
import {YesNoDialogComponent} from "../yesno-dialog/yesno-dialog.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'mbnm-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit, OnDestroy {
  view = 'sign in';
  signInForm: any = {};
  registerForm: any = {
    subscriptions: {
      newsletterEmailStatus: 'SUBSCRIBE_REQUESTED',
      smsMarketingStatus: null
    }
  };
  createAccount = true;
  signinRedirect = '/account';
  registerRedirect = '/checkout';
  page;
  popUpMessage: any;
  editUser: { fullname: any; phonenumber: any; email: any; email2: string; subscriptions: any };
  @ViewChild('passform') passwordForm;
  user: User;
  configs: UserDialogConfigs;
  private queryParamsSubRef: Subscription;

  passwordFormGroup: UntypedFormGroup = new UntypedFormGroup({
    old: new UntypedFormControl('', [<any>Validators.required, <any>Validators.minLength(6)]),
    new: new UntypedFormControl('', [<any>Validators.required, <any>Validators.minLength(6)]),
    confirm: new UntypedFormControl('', [<any>Validators.required, <any>Validators.minLength(6)])
  });

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    if (this.queryParamsSubRef && !this.queryParamsSubRef.closed) {
      this.queryParamsSubRef.unsubscribe();
    }
  }

  ngOnInit() {
    this.view = this.configs.view ? this.configs.view : 'sign in';
    if (this.router.url.includes('checkout')) {
      this.page = 'checkout';
      this.view = 'register';
    }

    this.queryParamsSubRef = this.route.queryParams
      .subscribe((params: any) => {
          this.user = this.configs.user ? this.configs.user : undefined;
          this.signinRedirect = this.configs.signinRedirect ? this.configs.signinRedirect : params.redir ? params.redir : undefined;
          this.registerRedirect = this.configs.registerRedirect;
          if (this.configs.page) { this.page = this.configs.page; }
          if (params.e) { this.signInForm.email = params.e; }
        });
  }

  public async signIn() {
    try {
      const user = await this.sessionService.signIn(this.signInForm.email, this.signInForm.password);
      this.dialogRef.close(user);
      if (!this.router.url.includes('checkout') &&
        !this.router.url.includes('buy') &&
        !this.router.url.includes('account')) {
        if (this.signinRedirect) {
          this.router.navigateByUrl(this.signinRedirect);
        }
        this.dialogRef.close(user);
      }
    } catch (error) {
      this.notificationService.createNotification('Email or password incorrect. Please check your login and try again.', 'warn');
    }
  }

  public closeWindow() {
    this.dialogRef.close();
  }

  public async register() {
    if (this.isUserValid()) {
      try {
        const user = await this.userService.register(this.registerForm);
        if (this.registerRedirect) {
          this.router.navigateByUrl(this.registerRedirect);
        }
        this.dialogRef.close(user);
      } catch (errorResponse: any) {
        if (errorResponse.error.ErrorCode === 'USER_EXISTS') {
          this.dialogRef.componentInstance.view = 'sign in';
          this.dialogRef.componentInstance.signinRedirect = '/checkout'
          this.signInForm.email = this.registerForm.email;
          this.popUpMessage = 'That user already exists!<br /><b>Please sign in instead.</b>';
        } else {
          this.notificationService.createNotification(errorResponse.error.Message ? errorResponse.error.Message : 'Unknown Error Occured', 'error');
        }
      }
    }
  }

  public resetPassword(): void {
    this.router.navigateByUrl('/account/reset-password');
    this.dialogRef.close();
  }

  private isUserValid(): boolean {
    let isValid = false;
    if (this.registerForm.fullname && this.registerForm.phonenumber && this.registerForm.email &&
        (!this.createAccount || (this.registerForm.password && this.registerForm.password === this.registerForm.confirmPassword))) {
      if (!this.registerForm.phonenumber.match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/)) {
        this.notificationService.createNotification('Phone number is not in the correct format. Please use ### ####### (10 digits)', 'error');
      } else if (!this.registerForm.email.match(
        /^[a-zA-Z0-9_\-]([a-zA-Z0-9_\-\.]*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)
      ) {
        this.notificationService.createNotification('Email address is not valid.', 'error');
      } else {
        isValid = true;
      }
    } else if (this.registerForm.password !== this.registerForm.confirmPassword) {
      this.notificationService.createNotification('Passwords do not match.', 'error');
    } else {
      this.notificationService.createNotification('All fields are required.','error');
    }
    return isValid;
  }

  public async savePasswordChange() {

    const oldPass = this.passwordFormGroup.get('old').value;
    const newPass = this.passwordFormGroup.get('new').value;
    const confirmPass = this.passwordFormGroup.get('confirm').value;

    const user = this.configs.user;
    if ((oldPass || user.isGuest) && newPass && confirmPass) {
      if (newPass !== confirmPass) {
        this.notificationService.createNotification('Passwords do not match.', 'error');
      } else if (newPass.length < 6) {
        this.notificationService.createNotification('Passwords must be at least 6 characters long.', 'error');
      } else {
        const guest = user.isGuest;
        try {
          const u = await this.userService.updatePassword(user.email, oldPass, newPass);
          this.setUser(u);
          this.notificationService.createNotification(guest ? 'Account created successfully!' : 'User password successfully updated.', 'success');
        } catch (error) {
          this.notificationService.createNotification('Invalid "Old Password"', 'error');
        } finally {
          this.resetPasswordForm();
        }
      }
    } else {
      this.notificationService.createNotification('All password fields are required to update your password.', 'error');
    }
  }

  private setUser(user=null): void {
    const subscribed = user ? user.subscribed : undefined;
    user = user ? user : user.currentUser;
    user.subscribed = subscribed;

    if (this.configs.user && !this.configs.user.isAnonymous) {
       this.editUser = {
        fullname: this.configs.user.fullname,
        phonenumber: this.configs.user.phonenumber,
        email: this.configs.user.email,
        email2: '',
        subscriptions: this.configs.user.subscriptions
      };
    }
  }

  public resetPasswordForm(): void {
    this.passwordForm.nativeElement.reset();
    this.dialogRef.close();
  }

  public showSMSDisclaimer($event): void {
    if ($event) {
      const dialogRef = this.dialog.open(YesNoDialogComponent, {});
      let instance = dialogRef.componentInstance;
      instance.data = {
        closeMethod: null,
        title: 'SMS Marketing Disclaimer',
        message: 'By checking this box, you agree to receive recurring automated promotional and personalized marketing ' +
          'text messages (e.g. cart reminders) from Matboard & More at the cell number used when signing up. Consent ' +
          'is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. ' +
          'Msg & data rates may apply. View <a href="/terms-conditions">Terms</a> & <a href="/privacy-policy">Privacy Policy</a>.',
          yesButtonText: 'Accept',
        noButtonText: 'Decline'
      };
      dialogRef.afterClosed().subscribe((result: boolean) => {
        this.registerForm.subscriptions.smsMarketingStatus = result ? 'SUBSCRIBE_REQUESTED' : null;
      });
    } else {
      this.registerForm.subscriptions.smsMarketingStatus = null;
    }
  }

  protected readonly close = close;
}
